var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"query_record"},[_c('mt-header',{staticStyle:{"font-size":"0.32rem","background":"#3985E1"},attrs:{"title":_vm.getWord('trade_history')}},[_c('mt-button',{attrs:{"slot":"left","icon":"back"},on:{"click":function($event){return _vm.left_click()}},slot:"left"},[_vm._v(_vm._s(_vm.getWord('back_to_previous')))])],1),(_vm.recordbool)?_c('div',[_c('div',{staticClass:"main_image"}),_c('span',[_vm._v(_vm._s(_vm.getWord('no_result6')))]),_c('span',[_vm._v(_vm._s(_vm.getWord('start_game2')))])]):_vm._e(),(!_vm.recordbool)?_c('div',{ref:"tradeHistory",attrs:{"id":"trade-history"}},[_c('table',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore_data),expression:"loadMore_data"}],staticClass:"main_table",attrs:{"cellspacing":"0","border":"0","infinite-scroll-disabled":"loading_data2","infinite-scroll-distance":"50"}},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.getWord('drawing_number')))]),_c('th',[_vm._v(_vm._s(_vm.getWord('content')))]),_c('th',[_vm._v(_vm._s(_vm.getWord('amount_of_betting')))]),_c('th',[_vm._v(_vm._s(_vm.getWord('result')))])]),_vm._l((_vm.list),function(item,i){return (_vm.type!=='wanneng' && _vm._TEMPLATE!=='template-3')?_c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(item.issue)+" "),_c('br'),_vm._v(" "+_vm._s(new Date(item.created_at*1000).toLocaleString().split(' ')[0])+" "),_c('br'),_vm._v(" "+_vm._s(new Date(item.created_at*1000).toTimeString().split(' ')[0])+" ")]),(item.lottery_class == 'WEI_LIAN'
                 || item.lottery_class == 'ALLNOTIN' 
                 || item.lottery_class == 'LIANMA' 
                 || item.lottery_class == 'ANIMAL_LIAN' 
                 || item.lottery_class == 'TEMA_HE_ANIMAL' 
                 || item.lottery_class == 'QIANER' 
                 || item.lottery_class == 'QIANSAN' 
                 || item.lottery_class == 'ZUER' 
                 || item.lottery_class == 'ZUSAN' 
                 || item.lottery_class.indexOf('LIANMA')>-1 
                 || item.lottery_class == 'ERZIBAISHIWEI' 
                 || item.lottery_class == 'ERZIBAIGEWEI' 
                 || item.lottery_class == 'ERZISHIGEWEI' 
                 || item.lottery_key.indexOf('LIAN_REN_')>-1  
                 || item.lottery_class.indexOf('ZUXUANSAN')>-1 
                 || item.lottery_class.indexOf('ZUXUANLIU')>-1
                 || item.lottery_type==='wanneng')?_c('td',[_vm._v(_vm._s(item.bet_name+'-'+item.bet_number))]):_c('td',[_vm._v(_vm._s(item.bet_name))]),_c('td',[_vm._v(_vm._s(item.money.toFixed(2)))]),_c('td',[(item.status==1)?_c('span',{staticStyle:{"color":"rgba(102,102,102,1)"}},[_vm._v(_vm._s(_vm.getWord('unsettled_account')))]):(item.status==2&&item.bonus_money=='0')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("-"+_vm._s(item.money.toFixed(2)))]):_c('span',{staticStyle:{"color":"#54B61C"}},[_vm._v("+"+_vm._s(Number((item.bonus_money-item.money).toFixed(2))))])])]):_vm._e()}),_vm._l((_vm.list),function(item,i){return (_vm.type==='wanneng' && _vm._TEMPLATE==='template-2')?_c('tr',{key:i,staticClass:"wanneng"},[_c('td',[_vm._v(" "+_vm._s(item.issue)+_vm._s(_vm.getWord('period'))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.bet_name)+" "),_c('br'),_vm._v(" "+_vm._s(item.bet_number)+" "),_c('br'),_vm._v(" "+_vm._s(new Date(item.created_at*1000).toLocaleString().split(' ')[0])+" "+_vm._s(new Date(item.created_at*1000).toTimeString().split(' ')[0])+" ")]),_c('td',[_vm._v(_vm._s(item.money.toFixed(2)))]),_c('td',[(item.status==1)?_c('span',{staticStyle:{"color":"rgba(102,102,102,1)"}},[_vm._v(_vm._s(_vm.getWord('unsettled_account')))]):(item.status==2&&item.bonus_money=='0')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("-"+_vm._s(item.money.toFixed(2)))]):_c('span',{staticStyle:{"color":"#54B61C"}},[_vm._v("+"+_vm._s(Number((item.bonus_money-item.money).toFixed(2))))])])]):_vm._e()})],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }